import loadScripts from '@anm/helpers/loadScripts';
import { toObject } from '@anm/helpers/parseParams';

declare global {
  interface Window {
    tap: any;
  }
}

type AffiliateLocalStore = {
  id: string;
  headerName: string;
};

type AffiliateParams = {
  ref: string;
  tap_a: string;
  tap_s: string;
  cjevent: string;
};

const tapfiliateLib = 'https://script.tapfiliate.com/tapfiliate.js';
const storageName = 'anm_referrral_program';

const loadLib = async () => loadScripts([tapfiliateLib]);

const initTapLib = () => {
  (function(t: any, a) {
    t.TapfiliateObject = a;
    t[a] =
      t[a] ||
      function() {
        (t[a].q = t[a].q || []).push(arguments);
      };
  })(window, 'tap');
};

const detectTap = async (tapId: string) => {
  await loadLib();
  initTapLib();

  window.tap('create', tapId, { integration: 'javascript' });
  window.tap('detect', { always_callback: false }, ({ id }: { id: string }) => {
    setAffiliateInfo(id, 'anm-ref-tap');
  });
};

const setAffiliateInfo = (id: string, headerName: string) => {
  localStorage.setItem(
    storageName,
    JSON.stringify({
      id,
      headerName
    })
  );
};

export const isAffiliate = () => {
  const { ref, tap_a, cjevent: cjeventId } = toObject<AffiliateParams>(
    location.search
  );

  return !!(cjeventId || tap_a || ref);
};

export const init = (tapKey: string) => {
  if (!isAffiliate()) return;

  const { ref, tap_a, cjevent: cjeventId } = toObject<AffiliateParams>(
    location.search
  );

  if (ref || tap_a) {
    detectTap(tapKey);
  }

  if (cjeventId) {
    setAffiliateInfo(cjeventId, 'anm-ref-cjevent');
  }
};

export const clear = () => localStorage.removeItem(storageName);

export const getHeaders = () => {
  const storage = localStorage.getItem(storageName);

  if (!storage) return {};

  const { id, headerName } = JSON.parse(storage || '{}') as AffiliateLocalStore;
  return { [headerName]: id };
};
